<template>
    <div id= "goalmanagepage">
    <div id="back">
      <a href="/taskmanager">--回到主页---</a>  
    </div>
    <div id="goalmanage">
    <div id="goalshow">
    <h1>Your goals<button id="addgoalbutton" @click="showaddgoalzone_func" > <a-tooltip title="create new goal" color="red" mouseEnterDelay="1">
      +</a-tooltip></button></h1>
    <ul v-for="item in items" :key="item.id" :class="'goalstatus_'+item.goalstatus" @click="showeditgoalzone_func(item)">
         {{item.name}}
    </ul>
    
    </div>
    <div id="addgoal" v-show="showaddgoalzone">
      <div id="userinfo" >
          💘尊贵的内测用户🤞🏼 
        </div>
        
    </div>
    
    <div id="editgoal" v-show="showeditgoalzone">
      <h1>编辑目标</h1>
       <div>
         <input placeholder="请输入你的目标" v-model="goalcontent" >
      </div>
       <a-select
          ref="select"
          v-model:value="selectedstatus"
          style="width: 120px"
          :options="options2"
          @focus="focus"
          @change="handleChange"
        ></a-select>
      <div>
      <button @click="modifygoal">修改目标</button>
      </div>
    </div>
    
    <!-- <div id="editgoal">
    
    </div> -->
    </div>
    
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    import configure_options from '../configure'
    import { defineComponent,ref } from 'vue';
    // const axios = require('axios').default;
    export default defineComponent({
      name: 'goalshow',
      props: {
        msg: String,
        
      },
      data() {
          return {
           items:[],
        
          }
    
      },
      setup(props,context) {
      // const value1 = ref(500);
        const handleChange = value => {
          console.log(`selected ${value}`);
          context.emit("testchild")
        };
        var currentgoal=null;
    
        var  options1 =  [{
                            id:0,
                            value:1,  
                          },{
                            id:1,
                            value:2
                          },{
                            id:2,
                            value:3
                          },
                          {
                            id:3,
                            value:4
                          },
                          {
                            id:4,
                            value:5
                          },
                          
                          ]
    
    
    
          var  options2 =  [{
                            id:0,
                            value:"已经放弃",  
                          },{
                            id:1,
                            value:"已经完成"
                          },{
                            id:2,
                            value:"正在进行中"
                          },
                          
                          ]
    
    
            function creategoal(){
                  var env =  configure_options["env"]
                
                  console.log(configure_options["app_setting"][env])
                  var serverhost =configure_options["app_setting"][env]["serverhost"]
    
              // alert("穆阿标点击")
              // alert(this.selectedpriority)
            
                if(this.goalcontent==""){
                  alert("目标不能为空")
                  return
                }
    
                var goal  ={"goal":this.goalcontent,"priority":this.selectedpriority}
                axios.post(serverhost + '/v1/creategoal',goal)
                 .then(response => {
                  console.log(response.data)
                  //可以将状态传入到父亲组件
                //    document.getElementById("addtaskinprojectzone").style.display="none"
                //  document.getElementById("goaltreedatapart").style.opacity=1
                //  document.getElementById("goaltreedatapart").style.pointerEvents="auto"
                //  document.getElementById("top").style.opacity=1
                //    emitter.emit("refresh_goaltree","createtaskinproject_component_ask")
                // 要让左侧进行对应的刷新
                this.showaddgoalzone = false
                      axios.get(serverhost+"/v1/goaljson").then(
              response=>{
                var data = response.data
                    console.log("~~~~~~~~~~~~~~~我正在打印所有未完成的任务~~~~~~~~~")
                console.log(data);
                this.items = data.goals
                // console.log(status);
                // 提取所有的Goal = [{goal1的所有task},{goal2的所有task},{}]
              }
       ).catch(error=>{console.log(error)})
    
    
    
              })
              .catch(error=>{
                console.log(error)
            
              if(error.response.status==902){
                  alert("不能使用相同的目标名")
              }else{
                alert("服务器出错")
              }})
                return 
            }
    
    
            function modifygoal(){
              // alert("检查海曙是否调用")
              if(this.currentgoal==null){
                alert("服务异常,请联系管理员")
                return
              }
              this.showeditgoalzone = false
              var env =  configure_options["env"]
              console.log("目前的目标是这样的")
              console.log(this.currentgoal)
                console.log(configure_options["app_setting"][env])
                var serverhost =configure_options["app_setting"][env]["serverhost"]
                var goalstatus ="unspecified"
                if(this.selectedstatus =="已经完成"){
                   goalstatus = "f"
                  //  alert(this.currentgoal.goalcode)
                }
                if(this.selectedstatus =="已经放弃"){
                   goalstatus = "g"
                  //  alert(this.currentgoal.goalcode)
                }
                var goal  ={"goalcode":this.currentgoal.goalcode,goal:this.goalcontent,"goalstatus":goalstatus,finishtime:"unspecified",priority:-1}
                axios.post(serverhost + '/v1/updategoal',goal)
                 .then(response => {
                  // alert("检查为什么没有触发")
                  console.log(response.data)})
                  console.log("检查所有的数据消息")
                  axios.get(serverhost+"/v1/goaljson").then(
              response=>{
                var data = response.data
                    console.log("~~~~~~~~~~~~~~~我正在打印所有未完成的任务~~~~~~~~~")
                console.log(data);
                this.items = data.goals
                // console.log(status);
                // 提取所有的Goal = [{goal1的所有task},{goal2的所有task},{}]
              }
       ).catch(error=>{console.log(error)})
    
            }
    
          function showaddgoalzone_func(){
            this.showaddgoalzone = true
          }
    
          function showeditgoalzone_func(goal){
            this.currentgoal = goal
            this.showeditgoalzone = true
            this.goalcontent = goal.name
            //  this.selectedstatus = goal.goalstatus
            if(goal.goalstatus=="finished"){
              this.selectedstatus ="已经完成"
            }
            if(goal.goalstatus=="unfinished" ||goal.goalstatus=="" ){
              this.selectedstatus ="正在进行中"
            }
              if(goal.goalstatus=="giveup"  ){
              this.selectedstatus ="已经放弃"
            }
    
    
            console.log(goal)
          }
    
    
        return {
          creategoal,
          modifygoal,
          goalcontent:ref(""),
          showaddgoalzone:ref(false),
          showeditgoalzone:ref(false),
          showaddgoalzone_func,
          showeditgoalzone_func,
          currentgoal,
          handleChange,
          selectedpriority:ref(0),
          selectedstatus:ref("未完成"),
          options1,
          options2,
       
        };
      },
      mounted(){
          var _this = this
               var env =  configure_options["env"]
           
            console.log(configure_options["app_setting"][env])
            var serverhost =configure_options["app_setting"][env]["serverhost"]
    
        axios.get(serverhost+"/v1/goaljson").then(
         response=>{
           var data = response.data
               console.log("~~~~~~~~~~~~~~~我正在打印所有未完成的任务~~~~~~~~~")
          console.log(data);
          _this.items = data.goals
          // console.log(status);
          // 提取所有的Goal = [{goal1的所有task},{goal2的所有task},{}]
         }
       ).catch(error=>{console.log(error)})
      
        
      }
    })
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped lang="scss">
    
    #goalmanagepage {
      display: flex;
      flex-direction:column;
    }
    
    #back{
      /* position: absolute;; */
      left: 10%;
      width: 200px;
    
      /* display:block; */
    }
    #goal{
        display:block;
    }
    
    #goalmanage{
      display: flex;
      /* justify-content: space-between; */
      margin-right: 100px;
    }
    
    #addgoalbutton{
      border: none;
      background: none;
    }
    
    
    .goalstatus_finished::after{
      color: red;
      content:" 已经完成了"
    }
    
    .goalstatus_giveup::after{
      color:rgb(129, 13, 238);
      content: " 放弃了x";
    }
    
    #goalshow{
      font-family: fangsong;
      text-align: left;
      margin-left: 50px;
      margin-right: 200px;
      /* position:absolute; */
      /* left: 40%; */
    }
    #addgoal{
      display: flex;
      flex-direction: column;
      /* width: 70%; */
    
    }
    
    
    </style>
    